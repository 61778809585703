import { Group, Highlight, Menu, Text } from "@mantine/core";
import { getReadableDate } from "app/utils/dateUtils";
import { HealthCheckGenerationMethod } from "appv2/api/health-checks/health-check-generation-method.type";
import { HealthCheckStatus } from "appv2/api/health-checks/health-check-status.type";
import { HealthCheckType } from "appv2/api/health-checks/health-check-type.type";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryDataTableToolTipHeader } from "appv2/components/orchestry-data-table/orchestry-data-table-tooltip-header.component";
import { OrchestryEllipsesButton } from "appv2/components/orchestry-ellipses-button/orchestry-ellipses-button.component";
import { OrchestryFontIcon } from "appv2/components/orchestry-font-icon/orchestry-font-icon.component";
import styles from "appv2/components/orchestry-menu/orchestry-menu.module.scss";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import { useEnumSuffixIntl } from "appv2/hooks/use-enum-suffix-intl.hook";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useHealthCheckCanEdit } from "../health-check/health-check/use-health-check-can-edit.hook";
import { useHealthCheckCanShare } from "../health-check/health-check/use-health-check-can-share.hook";
import { HealthCheckStatusPill } from "./health-check-status-pill.component";
import { useHealthCheckRouteParams } from "./use-health-check-route-params.hook";
import { useUserCanDeleteHealthCheck } from "./use-user-can-delete-health-check.hook";
export var HealthChecksTableNameColumn = function (value, highlightSearchWords) {
    return (React.createElement(Highlight, { c: "text-primary.0", size: "14px", fw: 500, lh: "20px", highlight: highlightSearchWords }, value));
};
export var HealthChecksTableTypeHeader = function () {
    var intl = useIntl();
    return (React.createElement(OrchestryDataTableToolTipHeader, { label: intl.formatMessage({ id: "page.health_checks.table.header.type", defaultMessage: "Type" }), tooltipLabel: intl.formatMessage({ id: "page.health_checks.table.header.type_tooltip", defaultMessage: "Identifies the scope and focus of the Health Check as well as how it was generated" }) }));
};
export var HealthChecksTableTypeColumn = function (row) {
    var intl = useIntl();
    var formatEnumSuffixIntl = useEnumSuffixIntl().formatEnumSuffixIntl;
    var healthCheckTypeIdPrefix = "page.health_checks.table.type_";
    var healthCheckTypeIdSuffix = HealthCheckType[row.getValue("Type")];
    var healthCheckTypeText = formatEnumSuffixIntl(healthCheckTypeIdPrefix, healthCheckTypeIdSuffix, "Tenant");
    var generatedMethodIdPrefix = "page.health_checks.table.generationMethod_";
    var generatedMethodIdSuffix = HealthCheckGenerationMethod[row.getValue("GenerationMethod")];
    var generatedMethodText = formatEnumSuffixIntl(generatedMethodIdPrefix, generatedMethodIdSuffix, "Manual");
    var iconClassName = row.getValue("GenerationMethod") === HealthCheckGenerationMethod.Manual ? ORCH_ICONS.settings2 : ORCH_ICONS.calendarCheck02;
    return (React.createElement(Group, { gap: 12 },
        React.createElement(OrchestryFontIcon, { iconClassName: iconClassName, fontSize: "24px", lineHeight: "24px", color: "var(--mantine-color-gray-6)" }),
        React.createElement(OrchestryText, { mt: 10, mb: 10, c: "text-secondary.0", size: "14px", fw: 400, lh: "20px" }, intl.formatMessage({ id: "page.health_checks.table.type", defaultMessage: "{type} ({generationMethod})" }, {
            type: healthCheckTypeText,
            generationMethod: generatedMethodText
        }))));
};
export var HealthChecksTrendDataStartDateColumn = function (row) {
    var intl = useIntl();
    var isDisabled = row.getValue("UseHistoricalTrend") !== true;
    var iconClassName = isDisabled ? ORCH_ICONS.x : ORCH_ICONS.check;
    var text = isDisabled
        ? intl.formatMessage({ id: "page.health_checks.table.start_date_disabled", defaultMessage: "Disabled" })
        : intl.formatMessage({ id: "page.health_checks.table.start_date_enabled", defaultMessage: "Enabled ({startDate})" }, {
            startDate: getReadableDate(row.getValue("StartDate"))
        });
    return (React.createElement(Group, { gap: 8 },
        React.createElement(OrchestryFontIcon, { iconClassName: iconClassName, fontSize: "24px", lineHeight: "24px", color: "var(--mantine-color-black)" }),
        React.createElement(OrchestryText, { c: "text-secondary.0", size: "14px", fw: 400, lh: "20px" }, text)));
};
export var HealthChecksTableStatusColumn = function (status) {
    return React.createElement(HealthCheckStatusPill, { status: status });
};
export var HealthChecksTableActionsColumn = function (row, onClickView, 
//onClickDownload: (uniqueId: string) => void, //For debugging only
onClickShare, onClickEdit, onClickDelete) {
    var intl = useIntl();
    var _a = useState(false), opened = _a[0], setOpened = _a[1];
    var userCanEdit = useHealthCheckCanEdit().userCanEdit;
    var userCanDeleteHealthCheck = useUserCanDeleteHealthCheck().userCanDeleteHealthCheck;
    var userCanEditHealthCheck = userCanEdit;
    var userCanShareHealthCheck = useHealthCheckCanShare().userCanShareHealthCheck;
    var userCanShare = userCanShareHealthCheck && row.getValue("Status") !== HealthCheckStatus.Draft;
    var status = row.getValue("Status");
    var userType = useHealthCheckRouteParams().userType;
    var clientDownloadLink = row.getValue("ClientDownloadLink");
    var partnerDownloadLink = row.getValue("PartnerDownloadLink");
    var downloadLink = userType === "partner" ? partnerDownloadLink : clientDownloadLink;
    var userCanDownload = status !== HealthCheckStatus.Draft && !!downloadLink;
    if (status === "Processing") {
        return null;
    }
    return (React.createElement(Menu, { opened: opened, onChange: setOpened, shadow: "md" },
        React.createElement(Menu.Target, null,
            React.createElement(OrchestryEllipsesButton, { ariaLabel: "More options", onClick: function () { return setOpened(true); } })),
        React.createElement(Menu.Dropdown, null,
            React.createElement(Menu.Item, { onClick: function (event) {
                    event.stopPropagation();
                    onClickView(row.getValue("UniqueId"));
                }, className: styles.orchestry_menu__item },
                React.createElement(Group, { gap: 12 },
                    React.createElement(OrchestryFontIcon, { iconClassName: ORCH_ICONS.eye, fontSize: "16px", lineHeight: "16px", color: "var(--mantine-color-gray-7)" }),
                    React.createElement(Text, { ff: "lexend", c: "text-secondary.0", size: "14px", fw: 300, lh: "20px" }, intl.formatMessage({ id: "controls.view", defaultMessage: "View" })))),
            userCanDownload && (React.createElement(Menu.Item, { className: styles.orchestry_menu__item, onClick: function (event) {
                    event.stopPropagation();
                    window.open(downloadLink);
                    //onClickDownload(row.getValue("UniqueId")); For debugging only
                } },
                React.createElement(Group, { gap: 12 },
                    React.createElement(OrchestryFontIcon, { iconClassName: ORCH_ICONS.download02, fontSize: "16px", lineHeight: "16px", color: "var(--mantine-color-gray-7)" }),
                    React.createElement(Text, { ff: "lexend", c: "text-secondary.0", size: "14px", fw: 300, lh: "20px" }, intl.formatMessage({ id: "controls.download", defaultMessage: "Download" }))))),
            userCanEditHealthCheck && (React.createElement(React.Fragment, null,
                userCanShare && (React.createElement(Menu.Item, { className: styles.orchestry_menu__item, onClick: function (event) {
                        event.stopPropagation();
                        onClickShare(row.getValue("UniqueId"));
                    } },
                    React.createElement(Group, { gap: 12 },
                        React.createElement(OrchestryFontIcon, { iconClassName: ORCH_ICONS.share06, fontSize: "16px", lineHeight: "16px", color: "var(--mantine-color-gray-7)" }),
                        React.createElement(Text, { ff: "lexend", c: "text-secondary.0", size: "14px", fw: 300, lh: "20px" }, intl.formatMessage({ id: "controls.share", defaultMessage: "Share" }))))),
                React.createElement(Menu.Item, { className: styles.orchestry_menu__item, onClick: function (event) {
                        event.stopPropagation();
                        onClickEdit(row.getValue("UniqueId"));
                    } },
                    React.createElement(Group, { gap: 12 },
                        React.createElement(OrchestryFontIcon, { iconClassName: ORCH_ICONS.edit02, fontSize: "16px", lineHeight: "16px", color: "var(--mantine-color-gray-7)" }),
                        React.createElement(Text, { ff: "lexend", c: "text-secondary.0", size: "14px", fw: 300, lh: "20px" }, intl.formatMessage({ id: "controls.edit", defaultMessage: "Edit" })))))),
            userCanDeleteHealthCheck && (React.createElement(Menu.Item, { className: styles.orchestry_menu__item, onClick: function (event) {
                    onClickDelete(row.getValue("UniqueId"), row.getValue("Name"));
                    event.stopPropagation();
                } },
                React.createElement(Group, { gap: 12 },
                    React.createElement(OrchestryFontIcon, { iconClassName: ORCH_ICONS.trash01, fontSize: "16px", lineHeight: "16px", color: "var(--mantine-color-gray-7)" }),
                    React.createElement(Text, { ff: "lexend", c: "text-secondary.0", size: "14px", fw: 300, lh: "20px" }, intl.formatMessage({ id: "controls.delete", defaultMessage: "Delete" }))))))));
};
