import AnimatedLoader from "app/components/common/animated-loader";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { InstallWizardContext } from "app/components/installer/installer-wizard";
import InstallService from "app/services/install";
import * as TenantCurrentStore from "app/store/tenants/current";
import { ServiceAppPermissionConfiguration, TenantLicenseStatus } from "app/store/tenants/types";
import moment from "moment";
import * as React from "react";
import { Collapse } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./access-code.component.scss";
var InstallerStepLicenseKey = React.memo(function () {
    var iwc = React.useContext(InstallWizardContext);
    var _a = React.useState(""), accessCode = _a[0], setAccessCode = _a[1];
    var _b = React.useState(false), verifying = _b[0], setVerifying = _b[1];
    var _c = React.useState(), verified = _c[0], setVerified = _c[1];
    var inputEl = React.useRef();
    var currentTenant = useSelector(function (state) { return state.tenantCurrentState.item; });
    var _d = React.useState(null), installedBy = _d[0], setInstalledBy = _d[1];
    var _e = React.useState(null), licenseType = _e[0], setLicenseType = _e[1];
    var _f = React.useState(null), licenseTypeStartedAsFullSubscribed = _f[0], setLicenseTypeStartedAsFullSubscribed = _f[1];
    React.useEffect(function () {
        if (currentTenant !== undefined && currentTenant.GUID !== undefined) {
            if (currentTenant.InstallCode !== undefined && currentTenant.InstallCode !== null) {
                if (accessCode == "") {
                    setAccessCode(currentTenant.InstallCode);
                    inputEl.current.value = currentTenant.InstallCode;
                }
            }
            if (currentTenant.LicenseStatus !== undefined) {
                setLicenseType(currentTenant.LicenseStatus);
                if (licenseTypeStartedAsFullSubscribed === null) {
                    setLicenseTypeStartedAsFullSubscribed(currentTenant.LicenseStatus === TenantLicenseStatus.Orchestry);
                }
            }
        }
    }, [currentTenant]);
    React.useEffect(function () {
        if (accessCode !== "") {
            setVerifying(true);
            setInstalledBy(null);
            setVerified(false);
            setTimeout(function () {
                if (accessCode === inputEl.current.value) {
                    InstallService.IsAccessCodeCorrect(accessCode).then(function (reply) {
                        setVerifying(false);
                        var replyAccess = reply;
                        if (replyAccess.error !== undefined && replyAccess.error === false) {
                            iwc.setMultipleStepProgress(["access-code"], true);
                            iwc.setAccessCode(accessCode);
                            setVerified(true);
                            iwc.dispatch(TenantCurrentStore.Update({ InstallCode: accessCode }));
                        }
                        else {
                            iwc.setMultipleStepProgress(["access-code"], false);
                            setVerified(false);
                            iwc.dispatch(TenantCurrentStore.Update({ InstallCode: null }));
                        }
                        if (reply.installedBy !== undefined && reply.installedBy !== null) {
                            setInstalledBy(reply.installedBy);
                        }
                    });
                }
            }, 500);
        }
        else {
            setVerified(undefined);
        }
    }, [accessCode]);
    React.useEffect(function () {
        if (licenseType !== null) {
            iwc.setServiceAppConfig(licenseType == TenantLicenseStatus.Orchestry || licenseType == TenantLicenseStatus.OrchestryTrial
                ? ServiceAppPermissionConfiguration.OrchestryReadWriteAll
                : ServiceAppPermissionConfiguration.OrchestryReadAll);
            if (licenseType != currentTenant.LicenseStatus) {
                iwc.dispatch(TenantCurrentStore.Update({ LicenseStatus: licenseType }));
            }
        }
    }, [licenseType]);
    var handleKeyDown = function (e) {
        //console.log(e.keyCode)
        // //const { cursor, result } = this.state
        // // arrow up/down button should select next/previous list element
        if (e.keyCode === 13) {
            e.preventDefault();
            if (verified) {
                iwc.gotoNextStep();
            }
        }
        //return false;
    };
    var isOrchestryOptionEnabled = function () {
        if (currentTenant.LicenseStatus !== undefined && currentTenant.LicenseStatus === TenantLicenseStatus.Orchestry) {
            return true;
        }
        if (currentTenant.LicenseStatus !== undefined &&
            (currentTenant.LicenseStatus === TenantLicenseStatus.OrchestryTrial || currentTenant.LicenseStatus === TenantLicenseStatus.Beacon) &&
            currentTenant.TrialStart !== undefined &&
            currentTenant.TrialStart !== null) {
            if (currentTenant.TrialStart !== undefined &&
                currentTenant.TrialStart != null &&
                moment(currentTenant.TrialStart)
                    .add(28, "day")
                    .toDate() > new Date()) {
                return true;
            }
        }
        return false;
    };
    var isBeaconOptionEnabled = function () {
        if (currentTenant.LicenseStatus !== undefined && currentTenant.LicenseStatus === TenantLicenseStatus.Beacon) {
            return true;
        }
        if (currentTenant.HasHadBeaconLicenseBefore !== undefined && currentTenant.HasHadBeaconLicenseBefore === true) {
            return true;
        }
        if (currentTenant.LastInstalledVersion == null) {
            return true;
        }
        return false;
    };
    return (React.createElement("div", { className: "installer-step-access-code fadein-500ms" },
        React.createElement("div", { className: "enter-access-code" },
            React.createElement("div", { className: "preload", style: { height: 0, width: 0, overflow: "hidden" } },
                React.createElement("i", { className: "fas fa-check" }),
                React.createElement("i", { className: "fas fa-times" })),
            React.createElement("div", { className: "title" }, "Enter License Key"),
            React.createElement("div", { className: "login-box" }, currentTenant !== undefined && currentTenant.GUID !== undefined ? (React.createElement(React.Fragment, null,
                React.createElement("input", { 
                    // placeholder="Enter access code"
                    value: accessCode, autoComplete: "off", spellCheck: "false", ref: inputEl, onChange: function (event) { return setAccessCode(event.target.value); }, onKeyDown: function (event) {
                        handleKeyDown(event);
                    } }),
                verifying === true ? (React.createElement("div", { className: "icon" },
                    React.createElement(AnimatedLoader, { size: "xxsmall" }))) : null,
                verifying === false && verified === true ? (React.createElement("div", { className: "icon yes" },
                    React.createElement("i", { className: "fas fa-check" }))) : verifying === false && verified === false ? (React.createElement("div", { className: "icon no" },
                    React.createElement("i", { className: "fas fa-times" }))) : null)) : (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 34, bodyWidth: 350 }))),
            React.createElement(Collapse, { appear: true, in: installedBy !== null },
                React.createElement("div", { className: "access-code-provided-by" },
                    "License Provider: ",
                    React.createElement("span", null, installedBy))),
            React.createElement("div", { className: "title select-license-type" }, "Select License Type"),
            licenseType === null ? (React.createElement("div", { className: "license-type-selection" },
                React.createElement("div", { className: "license-type" },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 200 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 28, marginTop: 15 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 80, marginTop: 15 })),
                React.createElement("div", { className: "license-type" },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 200 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 28, marginTop: 15 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 80, marginTop: 15 })))) : (React.createElement("div", { className: "license-type-selection" },
                React.createElement("div", { onClick: function () { return (isBeaconOptionEnabled() ? setLicenseType(TenantLicenseStatus.Beacon) : null); }, className: "license-type " + (licenseType === TenantLicenseStatus.Beacon ? "selected" : "") + (isBeaconOptionEnabled() === true ? " enabled" : " disabled") },
                    React.createElement("div", { className: "license-type-image" },
                        React.createElement("div", { className: "image", style: { backgroundImage: "url('/media/orchestry/beacon-license-type.png')" } })),
                    React.createElement("div", { className: "license-type-title" }, "Beacon"),
                    React.createElement("div", { className: "license-type-description" }, "Begin your Orchestry journey with a set of Microsoft 365 Management features including our proactive Recommendations engine and holistic tenant Health Check reporting."),
                    React.createElement("div", { className: "license-type-button" }, licenseType === TenantLicenseStatus.Beacon ? "Selected" : "Select")),
                React.createElement("div", { className: "license-type " +
                        (licenseType === TenantLicenseStatus.OrchestryTrial || licenseType === TenantLicenseStatus.Orchestry ? "selected" : "") +
                        (isOrchestryOptionEnabled() ? " enabled" : " disabled"), onClick: function () { return (isOrchestryOptionEnabled() ? setLicenseType(licenseTypeStartedAsFullSubscribed ? TenantLicenseStatus.Orchestry : TenantLicenseStatus.OrchestryTrial) : null); } },
                    React.createElement("div", { className: "license-type-image" },
                        React.createElement("div", { className: "image", style: { backgroundImage: "url('/media/orchestry/orchestry-license-type.png')" } })),
                    React.createElement("div", { className: "license-type-title" }, "Orchestry"),
                    React.createElement("div", { className: "license-type-description" }, "Advance your Orchestry journey with a full gamut of Microsoft 365 Management features including Provisioning, Lifecycle Management, Guest Management and guided resolutions of all Recommendations."),
                    React.createElement("div", { className: "license-type-button" },
                        React.createElement("div", null, licenseType === TenantLicenseStatus.OrchestryTrial || licenseType === TenantLicenseStatus.Orchestry ? "Selected" : "Select"))))))));
});
export default InstallerStepLicenseKey;
